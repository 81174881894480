import React from 'react';

import Footer from '../components/footer';
import Header from '../components/header';

import { Outlet } from 'react-router';

const Layout = function () {
    return (
        <>
            <Header />
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5 col-md-6 col-sm-10">
                        <div class="mt-2">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        );
};

export default Layout;