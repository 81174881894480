import React from 'react';
import { Link } from 'react-router-dom';

import './footer.css';

const Footer = () => {

    return (
        <div className="footer row align-items-space-between">
            <div class="col"></div>
            <div class="col"><p><a href="mailto:hello@iamcontactable.com">Contact</a></p></div>
            <div class="col"><Link to='/privacy'><p>Privacy</p></Link></div>
        </div>
    );
};

export default Footer;